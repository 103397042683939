const useNodes = true // true为使用静态配置节点数据  fase反之
const center = [113.013550, 22.832450] // 地图显示区域
const zoom = 10 // 地图显示大小
const nodesTotal = 6 // 静态节点数量
const nodesList = [
  // 佛山九江1
  {
    keepLive: true,
    latitude: 22.84,
    longitude: 113.02,
    networkIP: '112.74.63.204',
    networkPort: 9705
  },
  // 佛山九江2
  {
    keepLive: true,
    latitude: 22.92,
    longitude: 112.94,
    networkIP: '112.74.63.204',
    networkPort: 9705
  },
  // 佛山九江3
  {
    keepLive: true,
    latitude: 22.88,
    longitude: 113.03,
    networkIP: '112.74.63.204',
    networkPort: 9705
  },
  // 佛山南海1
  {
    keepLive: true,
    latitude: 23.04,
    longitude: 113.16,
    networkIP: '112.74.63.204',
    networkPort: 9705
  },
  // 佛山南海2
  {
    keepLive: true,
    latitude: 23.08,
    longitude: 113.05,
    networkIP: '112.74.63.204',
    networkPort: 9705
  },
  // 广州1
  {
    keepLive: true,
    latitude: 23.129162,
    longitude: 113.264434,
    networkIP: '112.74.63.204',
    networkPort: 9705
  }
]

export default {
  useNodes,
  center,
  zoom,
  nodesTotal,
  nodesList
}
